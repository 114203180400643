import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class DailyWater extends Component {
    render() {
        return (
            <div style={{width: '100%', height: '100%'}}>

                <h1 className="main-title-page">
                    สถานการณ์น้ำรายวัน
                </h1>

                <iframe src="https://backend.cmddi.go.th/api/req/pdf?url=http://water.rid.go.th/hydrology/NormalReport/dailyreport.pdf" title="daily-report" frameborder="0" height="100%" width="100%"></iframe>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DailyWater);