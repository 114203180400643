import React from 'react';

export function ContentStyle1(props) {
    return (
        <div className="content-style-1 is-active">
            <div className="icon"></div>
            <div className="content">
                {props.children}
            </div>
        </div>
    )
}

export function ContentStyle2(props) {
    return (
        <div className={`content-style-2 is-active ${props.inverse ? 'inverse' : ''}`}>
            <div className="icon">
                <span>
                    {props.title}
                </span>
            </div>
            <div className="content">
                {props.children}
            </div>
        </div>
    )
}