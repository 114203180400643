import React, { Component } from 'react';
import compose from 'recompose/compose'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getWeather } from '../actions/Weather';

import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
    return {
        weathers: state.WeatherReducer.weathers,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getWeather: getWeather
}, dispatch)

class CmmetWeather extends Component {

    componentDidMount() {
        this.props.getWeather()
        setInterval(
            () => {
                this.props.getWeather()
            },
            1000 * 60 * 60 // fetch every 1 hour
        )
    }

    render() {
        const { t } = this.props;
        return (
            <div className="wrap-weathers">
                {
                    (this.props.weathers.data) &&
                        <div>
                            {
                                Object.keys(this.props.weathers.data).map(key => {
                                    // console.log( this.props.weathers.data[key])
                                    let val = this.props.weathers.data[key]

                                    let key_str = val.key.trim()
                                    let unit_str = val.unit.trim()

                                    return (
                                        <div className="each" key={`weather-${key_str}`}>
                                            <div className="key">
                                                {t(`cmmetweather.${key_str}`)}
                                            </div>
                                            <div className="value">
                                                {val.value}
                                                
                                                
                                            </div>
                                            <div className="unit">
                                                {
                                                    (key_str === 'ฝนรวมวันนี้' || key_str === 'ฝนรวม 24 ชั่วโมง' || key_str === 'ฝนรวมทั้งเดือน' || key_str === 'ฝนรวมทั้งปี') ?
                                                        <span>
                                                            {t(`cmmetweather.unit.มม`)} 
                                                        </span>
                                                    :
                                                        <span>
                                                            {t(`cmmetweather.unit.${unit_str}`)}
                                                        </span>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                }
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(CmmetWeather);