import { showLoading, hideLoading } from 'react-redux-loading-bar'
const axios = require('axios');


export const setStations = (data) => ({
    type: 'SET_STATIONS',
    payload: data
})

export const setCurrentStation = (data) => ({
    type: 'SET_CURRENT_STATION',
    payload: data
});

export const setWaterData = (data) => ({
    type: 'SET_WATER_DATA',
    payload: data
})

export const setRainData = (data) => ({
    type: 'SET_RAIN_DATA',
    payload: data
})

export const setStorageData = (data) => ({
    type: 'SET_STORAGE_DATA',
    payload: data
})

export const setTopWaterData = (data) => ({
    type: 'SET_TOP_WATER_DATA',
    payload: data
})

export const setRainingData = (data) => ({
    type: 'SET_RAINING_DATA',
    payload: data
})

export const setCurrentDistrict = (data) => ({
    type: 'SET_CURRENT_DISTRICT',
    payload: data
})

export const setWaterStationStatus = (data) => ({
    type: 'SET_WATER_STATION_STATUS',
    payload: data
})

export const setRainStationStatus = (data) => ({
    type: 'SET_RAIN_STATION_STATUS',
    payload: data
})

export const setStorageStationStatus = (data) => ({
    type: 'SET_STORAGE_STATION_STATUS',
    payload: data
})

export function getRainingData() {
    return (dispatch) => {
        dispatch(showLoading())
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/station/getRainingData`)
            .then(function(result) {
                dispatch(setRainingData(result.data))
                dispatch(hideLoading())
                return result.data
            })
    }
}

export function getTopWaterStations() {
    return (dispatch) => {
        dispatch(showLoading())
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/station/getTopWaterData`)
            .then(function(result) {
                dispatch(setTopWaterData(result.data))
                dispatch(hideLoading())
                return result.data
            })
    }
}

export function getStations() {
    return (dispatch) => {
        dispatch(showLoading())
        // dispatch(setLoading(true))
        // dispatch(setStations([]))
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/station/get`)
            .then(function(result) {
                dispatch(setStations(result.data))
                dispatch(hideLoading())
                return result.data
            })
    }
}

export function getWaterData(value, days = 0) {
    return (dispatch) => {
        dispatch(showLoading())
        // dispatch(setLoading(true))
        // dispatch(setStations([]))
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/station/getWaterData?id=${value.id}&days=${days}&source=${value.source}`)
            .then(function(result) {
                dispatch(setWaterData(result.data))
                dispatch(hideLoading())
                return result.data
            })
    }
}

export function getRainData(value, days = 0) {
    return (dispatch) => {
        dispatch(showLoading())
        // dispatch(setLoading(true))
        // dispatch(setStations([]))
        console.log('get rain')
        console.log(value)
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/station/getRainData?id=${value.id}&days=${days}&source=${value.source}`)
            .then(function(result) {
                dispatch(setRainData(result.data))
                dispatch(hideLoading())
                return result.data
            })
    }
}

export function getStorageData(value, days = 0) {
    return (dispatch) => {
        dispatch(showLoading())
        // dispatch(setLoading(true))
        // dispatch(setStations([]))
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/station/getStorageData?id=${value.id}&days=${days}&source=${value.source}`)
            .then(function(result) {
                dispatch(setStorageData(result.data))
                dispatch(hideLoading())
                return result.data
            })
    }
}