import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getWarningStatus } from '../actions/Notification';

function mapStateToProps(state) {
    return {
        warningStatus: state.NotificationReducer.warningStatus
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getWarningStatus: getWarningStatus
}, dispatch)

class NotificationBeforeMap extends Component {
    componentDidMount() {
        this.props.getWarningStatus()
        setInterval(
            () => {
                this.props.getWarningStatus()
            },
            1000 * 60 * 5 // fetch every 5 min
        )
    }

    render() {
        return (
            <div>
            {
                this.props.warningStatus.length !== 0 &&
                    <div className={`notification level-${this.props.warningStatus.warning_status_status.id}`}>
                        <span className="title">[{this.props.warningStatus.warning_status_status.text}]</span>
                        {
                            this.props.warningStatus.status &&
                                <span className="description">
                                    {this.props.warningStatus.warning_status.text}
                                </span>
                        }
                    </div>
            }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationBeforeMap);