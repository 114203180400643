import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// import Box from '@material-ui/core/Box';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class Block extends Component {

    constructor(props) {
		super(props);
		this.state = {
            current_image : '',
            current_date : ''
        }
    }

    componentDidMount() {
        if (this.props.data.data.length) {
            this.setState({
                ...this.state,
                current_image: this.props.data.data[0].image_url,
                current_date: this.props.data.data[0].datetime
            })
        }
    }

    changeImage = (obj) => {
        this.setState({
            ...this.state,
            current_image: obj.image_url,
            current_date: obj.datetime
        })
    }

    render() {
        return (
            <div className="each-block">
                <div className="image">
                    <strong>
                        {moment(this.state.current_date).add(543, 'years').format('dddd ที่ D MMMM YYYY เวลา HH:mm น.')}
                    </strong>
                    <img src={this.state.current_image} alt="airmap-block" />
                </div>
                <div className="date">
                    <strong>
                        เลือกเวลา
                    </strong>
                    <ul>
                        {
                            this.props.data.data.map((v, i) => {
                                return (
                                    <li key={i}>
                                        <button className={this.state.current_date === v.datetime ? 'active' : ''} onClick={ () => { this.changeImage(v) } }>
                                            {/*{v.datetime}*/}
                                            {moment(v.datetime).add(543, 'years').format('D MMM YYYY HH:mm น.')}
                                        </button>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Block);