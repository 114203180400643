import React, { Component } from 'react';
import { connect } from 'react-redux';
import Map1 from '../map1/Map1';
import SelectDistrict from '../elements/SelectDistrict';
import { Grid } from '@material-ui/core';
import NotificationBeforeMap from '../elements/NotificationBeforeMap';
// import DangerIcon from '../map1/markers/DangerIcon';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class Content1 extends Component {
    render() {
        return (
            <div className="in-content1">
                <Grid container spacing={3} className="wrap-before-map">
                    <Grid item xs={12} sm={12} xl={3} className="select-district">
                        <SelectDistrict></SelectDistrict>
                    </Grid>
                    <Grid item xs={12} sm={12} xl={9} className="wrap-notification">
                        <NotificationBeforeMap></NotificationBeforeMap>
                    </Grid>
                </Grid>

                <Grid container spacing={3} className="wrap-main-map">
                    <Grid item sm={12} className="main-map">
                        <Map1></Map1>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Content1);