const initState = {
  loading: false,
  loading_popup: false
}

const LoadingReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload
        // loading: true
      }
    case 'SET_LOADING_POPUP':
      return {
        ...state,
        loading_popup: action.payload
        // loading: true
      }
    default:
      return state
  }
}

export default LoadingReducer