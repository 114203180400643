const axios = require('axios');

export const setAirMaps = (data) => ({
    type: 'SET_AIRMAPS',
    payload: data
})

export function getAirMaps() {
    return (dispatch) => {
        // dispatch(setLoading(true))
        // dispatch(setStations([]))
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/air-map/getData`)
        // return axios.get(`${process.env.REACT_APP_BACKEND_URL}/json/airmap.json`)
            .then(function(result) {
                dispatch(setAirMaps(result.data))
                //   dispatch(setLoading(false))
                return result.data
            })
    }
}