import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class Forecast7Day extends Component {
    render() {
        return (
            <div style={{width: '100%', height: '100%'}}>

                <h1 className="main-title-page">
                    พยากรณ์ฝนล่วงหน้า 7 วัน
                </h1>

                <iframe src="https://backend.cmddi.go.th/api/req/pdf?url=https://www.tmd.go.th/programs/uploads/reports/7dForecastRegions.pdf" title="forecast-7-day" frameborder="0" height="100%" width="100%"></iframe>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Forecast7Day);