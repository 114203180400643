import React, { Component } from 'react';
import { connect } from 'react-redux';
import Map1Information from '../map1Information/Map1Information';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class Content2 extends Component {
    render() {
        return (
            <div className="in-content2">

                <div className="content-box">
                    <Map1Information></Map1Information>
                </div>
                
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Content2);