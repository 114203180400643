import React, { Component } from 'react';
import { connect } from 'react-redux';
import StationsBlock from './StationsBlock';
import StationCurrent from './StationCurrent';
import { SwipeableDrawer } from '@material-ui/core';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class PageStationHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent
    ) => {

        console.log(open)

        // if ( event && (event.type === 'keydown') && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
        //   return;
        // }

        this.setState({
            ...this.state,
            open: open
        })
    }

    render() {
        return (
            <div className="page-station-history">
                <SwipeableDrawer
                    anchor="left"
                    open={this.state.open}
                    onClose={this.toggleDrawer(false)}
                    onOpen={this.toggleDrawer(true)}
                    >
                    <div className="station-history stations-block hidden-desktop" style={{width: '250px'}}>
                        <StationsBlock></StationsBlock>
                    </div>
                </SwipeableDrawer>

                <button onClick={this.toggleDrawer(true)} className="hidden-desktop select-station">
                    เลือกสถานี
                </button>

                <div className="station-history stations-block hidden-mobile" style={{width: '250px'}}>
                    <StationsBlock></StationsBlock>
                </div>
                
                <div className="stations-info-block">
                    <StationCurrent></StationCurrent>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageStationHistory);