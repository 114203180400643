const initState = {
  stations: [],
  currentStation: null,
  waterData: [],
  rainData: [],
  storageData: [],
  topWaterData: [],
  rainingData: {
    raining: 0,
    no_raining: 0
  },
  currentDistrict: {value: '', label: '-- กรุณาเลือกอำเภอ --', center: {lat: 18.78,lng: 98.97}, zoom: 8 },
  stationStatus: {
    water: false,
    rain: false,
    storage: false
  }
}

const StationReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_STATIONS':
      return {
        ...state,
        stations: action.payload
      }
    case 'SET_CURRENT_STATION':
      return {
        ...state,
        currentStation: action.payload
      }
    case 'SET_WATER_DATA':
      return {
        ...state,
        waterData: action.payload
      }
    case 'SET_RAIN_DATA':
      return {
        ...state,
        rainData: action.payload
      }
    case 'SET_STORAGE_DATA':
      return {
        ...state,
        storageData: action.payload
      }
    case 'SET_TOP_WATER_DATA':
      return {
        ...state,
        topWaterData: action.payload
      }
    case 'SET_RAINING_DATA':
      return {
        ...state,
        rainingData: action.payload
      }
    case 'SET_CURRENT_DISTRICT':
      return {
        ...state,
        currentDistrict: action.payload
      }
    case 'SET_WATER_STATION_STATUS':
      return {
        ...state,
        stationStatus: {
          ...state.stationStatus,
          water: action.payload
        }
      }
    case 'SET_RAIN_STATION_STATUS':
      return {
        ...state,
        stationStatus: {
          ...state.stationStatus,
          rain: action.payload
        }
      }
    case 'SET_STORAGE_STATION_STATUS':
      return {
        ...state,
        stationStatus: {
          ...state.stationStatus,
          storage: action.payload
        }
      }
    default:
      return state
  }
}
  
export default StationReducer