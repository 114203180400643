import React, { Component } from 'react';
import compose from 'recompose/compose'
import { connect } from 'react-redux';
import { Stepper, Step } from '../elements/Stepper';
import { ContentStyle2 } from '../elements/Content';
import { bindActionCreators } from 'redux';

import CmmetWeather from './../cmmetWeather/CmmetWeather';
import { getTopWaterStations, getRainingData } from '../actions/Station';

import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
    return {
        topWaterData: state.StationReducer.topWaterData,
        rainingData: state.StationReducer.rainingData
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getTopWaterStations: getTopWaterStations,
    getRainingData: getRainingData
}, dispatch)

class Map1Information extends Component {

    componentDidMount() {
        this.props.getTopWaterStations()
        this.props.getRainingData()
        setInterval(
            () => {
                this.props.getTopWaterStations()
                this.props.getRainingData()
            },
            1000 * 60 * 60 // fetch every 1 hour
        )
    }

    render() {

        const { t } = this.props;

        return (
            <div className="wrap-main-map-information">
                <Stepper isVertical>
                    <Step title={t('น้ำท่าสายหลัก')}>
                        <div className="Content">
                            <table className="table top-water-data">
                                <thead>
                                    <tr>
                                        <th>

                                        </th>
                                        <th>
                                            {t('สถานี')}
                                        </th>
                                        <th className="text-right">
                                            {t('ระดับน้ำ')}
                                        </th>
                                        <th className="text-right">
                                            {t('ปริมาณน้ำ')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.topWaterData &&
                                            this.props.topWaterData.map((value, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>
                                                            {value.station}
                                                        </td>
                                                        <td>
                                                            {value.station_name}
                                                        </td>
                                                        <td className="text-right">
                                                            {value.level}
                                                        </td>
                                                        <td className="text-right">
                                                            {value.dischg}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Step>          
                    <Step title={t('น้ำฝน')}>
                        <div className="Content">
                            <ContentStyle2 title={t('มีฝน')}>
                                {this.props.rainingData.raining} {t('stations')}
                            </ContentStyle2>
                            <ContentStyle2 title={t('ไม่มีฝน')} inverse={true}>
                                {this.props.rainingData.no_raining} {t('stations')}
                            </ContentStyle2>
                        </div>
                    </Step>          
                    <Step title={t('สภาพอากาศ')}>
                        <div className="Content">
                            <CmmetWeather />
                            {/* <CmmetWeather></CmmetWeather> */}
                        </div>
                    </Step>
                </Stepper>
            </div>
        );
    }
}

// withTranslation()

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps,
// )(Map1Information);

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(Map1Information);