import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WaterInformation from './WaterInformation';
import RainInformation from './RainInformation';
import StorageInformation from './StorageInformation';
import { ButtonGroup, Button } from '@material-ui/core';
import { getStations, setCurrentStation, getWaterData, getRainData, getStorageData } from "./../actions/Station";

function mapStateToProps(state) {
    return {
        currentStation: state.StationReducer.currentStation,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getStations: getStations,
    setCurrentStation: setCurrentStation,
    getWaterData: getWaterData,
    getRainData: getRainData,
    getStorageData: getStorageData
}, dispatch)


class StationCurrent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            days_active: 1,
            width: 0, 
            height: 0,
            area_height: 600
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }

    componentDidMount() {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
        setTimeout(() => {
            this.getStationInformation(this.props.currentStation, 3)
        }, 2000)
        
    } 

    getStationInformation = (obj, days) => {
        if (obj) {
            if (obj.type === 'rain') {
                this.props.getRainData(obj, days)
            } else if (obj.type === 'water') {
                this.props.getWaterData(obj, days)
            } else if (obj.type === 'storage') {
                this.props.getStorageData(obj, days)
            }
        }
        this.setState({
            ...this.state,
            days_active: days
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let area_height = window.innerHeight - 300

        this.setState({ 
            ...this.state,
            width: window.innerWidth, 
            height: window.innerHeight,
            area_height: area_height
        })
    }

    render() {
        // console.log(';;;;fdafd;v')
        // console.log(this.props.currentStation)

        let value = this.props.currentStation

        return (
            <div style={{width: '100%', height: '100%'}}>
                {
                    this.props.currentStation &&
                        <div className="">
                            {
                                value.type === 'water' &&
                                    <div className="station-title" type="water">
                                        <div className="title">
                                            {value.station_name}
                                        </div>
                                        <div className="sub-title">
                                            {value.sub_district} {value.district} {value.province}
                                        </div>
                                    </div>
                            }

                            {
                                value.type === 'rain' &&
                                    <div className="station-title" type="rain">
                                        <div className="title">
                                            {value.station}
                                        </div>
                                        <div className="sub-title">
                                            {value.sub_district} {value.district} {value.province}
                                        </div>
                                    </div>
                            }

                            {
                                value.type === 'storage' &&
                                    <div className="station-title" type="storage">
                                        <div className="title">
                                            {value.station}
                                        </div>
                                        <div className="sub-title">
                                            {value.sub_district} {value.district} {value.province}
                                        </div>
                                    </div>
                            }
                            {
                                // Filter
                                <div className="filter-by-days">
                                    <ButtonGroup color="primary" aria-label="small outlined primary button group">
                                        {/*<Button onClick={() => { this.getStationInformation(value, 1) }}>1 วัน</Button>*/}
                                        <Button onClick={() => { this.getStationInformation(value, 3) }} disabled={this.state.days_active === 3 ? true : false}>3 วัน</Button>
                                        <Button onClick={() => { this.getStationInformation(value, 7) }} disabled={this.state.days_active === 7 ? true : false}>7 วัน</Button>
                                        <Button onClick={() => { this.getStationInformation(value, 14) }} disabled={this.state.days_active === 14 ? true : false}>14 วัน</Button>
                                        <Button onClick={() => { this.getStationInformation(value, 30) }} disabled={this.state.days_active === 30 ? true : false}>1 เดือน</Button>
                                        <Button onClick={() => { this.getStationInformation(value, 90) }} disabled={this.state.days_active === 90 ? true : false}>3 เดือน</Button>
                                        <Button onClick={() => { this.getStationInformation(value, 180) }} disabled={this.state.days_active === 180 ? true : false}>6 เดือน</Button>
                                        <Button onClick={() => { this.getStationInformation(value, 365) }} disabled={this.state.days_active === 365 ? true : false}>1 ปี</Button>
                                    </ButtonGroup>
                                </div>
                            }
                        </div>
                }
                {
                    this.props.currentStation &&
                        <div style={{width: '100%', height: this.state.area_height}}>
                            {
                                this.props.currentStation.type === 'water' &&
                                    <WaterInformation></WaterInformation>
                            }
                            {
                                this.props.currentStation.type === 'rain' &&
                                    <RainInformation></RainInformation>
                            }
                            {
                                this.props.currentStation.type === 'storage' &&
                                    <StorageInformation></StorageInformation>
                            }
                        </div>
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StationCurrent);