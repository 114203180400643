import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAirMaps } from '../actions/AirMap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Block from './Block';

function mapStateToProps(state) {
    return {
        airMaps: state.AirMapReducer.airMaps
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getAirMaps: getAirMaps
}, dispatch)

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={0}>{children}</Box>}
      </Typography>
    );
  }

class AirMap extends Component {

    constructor(props) {
		super(props);
		this.state = {
			tabValue: 0
        }
    }

    componentDidMount() {
        this.props.getAirMaps()
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({
            ...this.state,
            tabValue: newValue
        })
    }

    render() {
        return (
            <div className="air-map">

                <h1 className="main-title-page">
                    แผนที่อากาศ
                </h1>

                <div className="tabs">
                    <Tabs 
                        value={this.state.tabValue} 
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="primary"
                        onChange={this.handleTabChange} 
                        aria-label="Airmap tabs">
                        {
                            this.props.airMaps.map((v, i) => {
                                return (
                                    <Tab label={v.title} {...a11yProps(i)} key={i} />
                                )
                            })
                        }
                    </Tabs>
                </div>

                {
                    this.props.airMaps.map((v, i) => {
                        return (
                            <TabPanel value={this.state.tabValue} index={i} key={i}>
                                <Block data={v} />
                            </TabPanel>
                        )
                    })
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AirMap);