import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose'
import {
    Link
  } from "react-router-dom";
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        // classes: styles()
    };
}

class Footer extends Component {
    render() {

        const { t } = this.props;

        return (
            <footer>
                <ul className="menu">
                    <li>
                        <Link to="/">
                            <span>{t('footer.แผนที่')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/air-map">
                            <span>{t('footer.แผนที่อากาศ')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/satellite-map">
                            <span>{t('footer.ภาพถ่ายดาวเทียม')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/stations-history">
                            <span>{t('footer.ข้อมูลระดับน้ำย้อนหลัง')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="radar">
                            <span>{t('footer.เรดาร์กลุ่มฝน')}</span>
                        </Link>
                    </li>
                   
                    
                    <li>
                        <Link to="forecast-7-day">
                            <span>{t('footer.พยากรณ์ฝนล่วงหน้า 7 วัน')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/warning">
                            <span>{t('footer.ประกาศเตือนภัย')}</span>
                        </Link>
                    </li>
                    <li>
                        {/* <a href="http://water.rid.go.th/hydrology/NormalReport/dailyreport.pdf"><span>สถาณการณ์น้ำรายวัน</span></a> */}
                        <Link to="daily-water">
                            <span>{t('footer.สถานการณ์น้ำรายวัน')}</span>
                        </Link>
                    </li>
                    <li>
                        <a href="https://backend.cmddi.go.th/api/req/url?url=http://hydro-1.rid.go.th/" target="_blank" rel="noopener noreferrer"><span>{t('footer.ศูนย์อุทกวิทยาภาคเหนือตอนบน')}</span></a>
                        {/* <Link to="#">
                            <span>ศูนย์อุทกวิทยาภาคเหนือตอนบน</span>
                        </Link> */}
                    </li>
                    <li>
                        <Link to="/reservior">
                            <span>{t('footer.ปริมาณน้ำในอ่างเก็บน้ำ')}</span>
                        </Link>
                    </li>
                    <li>
                        <a href="https://backend.cmddi.go.th/api/req/url?url=http://tele-pwps.dwr.go.th"  target="_blank" rel="noopener noreferrer"><span>{t('footer.ระบบพยากรณ์ และเตือนภัยทรัพยากรน้ำ')}</span></a>
                        {/* <Link to="#">
                            <span>ระบบพยากรณ์ และเตือนภัยทรัพยากรน้ำ</span>
                        </Link> */}
                    </li>
                    <li>
                        {/* <a href="http://ews.dwr.go.th/ews/province_data.php"  target="_blank"><span>ระบบปฏิบัติการเฝ้าระวัง และเตือนภัยน้ำ-ดินถล่ม</span></a> */}
                        {/* <Link to="#">
                            <span>ระบบปฏิบัติการเฝ้าระวัง และเตือนภัยน้ำ-ดินถล่ม</span>
                        </Link> */}
                        <form action="http://ews.dwr.go.th/ews/province_data.php" method="POST" target="_blank">
                            <input type="hidden" name="onprov" value="เชียงใหม่" />
                            <input type="submit" value={t('footer.ระบบปฏิบัติการเฝ้าระวัง และเตือนภัยน้ำ-ดินถล่ม')} />
                        </form>
                    </li>
                </ul>
            </footer>
        );
    }
}

Footer.propTypes = {
    // classes: PropTypes.object.isRequired,
};

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Footer);